import React, { } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import TablePagination from '@material-ui/core/TablePagination';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Loading from '../../UI/Loading';
import TablePaginationActions from '../../../shared/components/Table/Actions';
import Button from '@material-ui/core/Button';
import { dateFormat } from '../../../util/DateUtil';

const useStyles = makeStyles({
    paper: {
        marginTop: '20px'
    },
    table: {
        minWidth: 650,
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    novoRegistro: {
        marginTop: '30px',
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


export default function ObservacaoAlunoTableV2({
    tdp,
    alunoTurma,
    isLoading,
    handleChangePage,
    observacoesAluno,
    handleDelete,
    setObservacaoAluno
}) {
    const classes = useStyles();

    return (
        <Container className={classes.paper} component={Paper}>
            <Button
                className={classes.novoRegistro}
                onClick={() => setObservacaoAluno({
                    tdp: tdp,
                    aluno: alunoTurma.aluno
                })}
                variant="contained"
                color="primary">
                Novo Observação Aluno
            </Button>
            {isLoading ? <Loading description='Carregando Observações Aluno...' /> :
                <>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Conteúdo</TableCell>
                                <TableCell>Data</TableCell>
                                <TableCell>Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {observacoesAluno.content.map((observacaoAluno) => (
                                <TableRow key={observacaoAluno.codigo}>
                                    <TableCell style={{
                                        width: '50%',
                                        textAlign: 'justify',
                                    }}>{observacaoAluno.conteudo}</TableCell>
                                    <TableCell>{dateFormat(observacaoAluno.data)}</TableCell>
                                    <TableCell>
                                        <EditIcon
                                            fontSize='small'
                                            color="secondary"
                                            style={{ marginRight: '10px' }}
                                            className={classes.cursorLink}
                                            onClick={() => setObservacaoAluno(observacaoAluno)}
                                        />
                                        <DeleteIcon
                                            fontSize='small'
                                            className={classes.cursorLink}
                                            onClick={() => { handleDelete(observacaoAluno.codigo) }}
                                            style={{ marginRight: '10px' }}
                                            color="secondary"
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        rowsPerPage={observacoesAluno.size ? observacoesAluno.size : 0}
                        component="div"
                        count={observacoesAluno.totalElements}
                        page={observacoesAluno.number}
                        onPageChange={handleChangePage}
                        ActionsComponent={TablePaginationActions}
                        labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
                    />
                </>
            }

        </Container>
    );
}