import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ObservacaoAlunoFormV2 from './ObservacaoAlunoFormV2';
import ObservacaoAlunoTableV2 from './ObservacaoAlunoTableV2';
import AlunoTurmaProfileView from '../../AlunoTurma/AlunoTurmaProfileView';
import { page, remove, save } from '../api-observacao-aluno';
import { errorMessage } from '../../../util/ConfirmAlertUtil';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import TurmaDisciplinaProfessorProfile from '../../TDP/TurmaDisciplinaProfessorProfile';
import AlunoTurmaTableSelectView from '../../AlunoTurma/AlunoTurmaTableSelectView';
import { findByTurma } from '../../AlunoTurma/api-aluno-turma';
import TDPTableSelectView from '../../TDP/TDPTableSelectView';
import { findByCurrentUser } from '../../TDP/api-tdp';
import { ALUNO, PROFESSOR, RESPONSAVEL } from '../../Usuario/Perfil';
import { findByPerfil } from '../../Usuario/api-usuario-perfil';
import { findAll as findAllDisciplinas } from '../../Disciplina/api-disciplina';
import { findByAnoLetivo as findTurmasByAnoLetivo } from '../../Turma/api-turma';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
    },
    table: {
        paddingTop: '20px;',
        margin: '20px'
    },
    title: {
        marginBottom: '20px'
    },
});

export default function ObservacaoAlunoContainerV2() {
    const classes = useStyles();
    const usuario = useSelector(state => state.usuario);
    const [tdp, setTdp] = useState(null);
    const [alunoTurma, setAlunoTurma] = useState(null);
    const [observacoesAluno, setObservacoesAluno] = useState({
        content: [],
        totalElements: 0,
        number: 0,
        numberOfElements: 0
    })
    const [observacaoAluno, setObservacaoAluno] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [alunosTurma, setAlunosTurma] = useState([]);
    const [turma, setTurma] = useState(null);
    const [disciplina, setDisciplina] = useState(null);
    const [turmas, setTurmas] = useState([]);
    const [disciplinas, setDisciplinas] = useState([]);
    const [usuarioPerfil, setUsuarioPerfil] = useState(null);
    const [usuariosPerfil, setUsuariosPerfil] = useState([]);
    const [tdps, setTdps] = useState([]);
    const [tdpsAll, setTdpsAll] = useState([]);


    useEffect(() => {
        setIsLoading(true);
        findByCurrentUser().then((data) => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setTdps(data);
                    setTdpsAll(data);
                }
            }
            setIsLoading(false);
        });

        if (usuario &&
            usuario.perfil !== ALUNO &&
            usuario.perfil !== RESPONSAVEL) {
            findTurmasByAnoLetivo().then((data) => {
                if (data) {
                    if (data.error) {
                        errorMessage(data.message);
                    } else {
                        setTurmas(data);
                    }
                }
            })

            findAllDisciplinas().then((data) => {
                if (data) {
                    if (data.error) {
                        errorMessage(data.message)
                    } else {
                        setDisciplinas(data);
                    }
                }
            })

            findByPerfil(PROFESSOR).then((data) => {
                if (data) {
                    if (data.error) {
                        errorMessage(data.message);
                    } else {
                        setUsuariosPerfil(data);
                    }
                }
            })
        }


    }, [usuario]);


    useEffect(() => {
        if (tdp && alunoTurma) {
            setIsLoading(true);
            page(tdp.codigo, alunoTurma.aluno.codigo, 0).then((data) => {
                if (data) {
                    if (data.error) {
                        errorMessage(data.message);
                    } else {
                        setObservacoesAluno(data);
                    }
                }
                setIsLoading(false);
            })
        }

    }, [tdp, alunoTurma]);

    useEffect(() => {
        if (tdp) {
            setIsLoading(true);
            findByTurma(tdp.turma.codigo).then((data) => {
                if (data) {
                    if (data.error) {
                        errorMessage(data.message);
                    } else {
                        setAlunosTurma(data);
                    }
                }
                setIsLoading(false);
            })
        }


    }, [tdp]);

    const handleChangePage = (event, newPage) => {
        setIsLoading(true);
        page(tdp.codigo, alunoTurma.aluno.codigo, newPage).then((data) => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setObservacoesAluno(data)
                }
            }
            setIsLoading(false);
        });
    };



    const handleDelete = (codigo) => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar esta observação?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        remove(codigo).then((data) => {
                            if (data) {
                                if (data.error) {
                                    errorMessage(data.message);
                                } else {
                                    setObservacoesAluno((prevState) => ({
                                        ...prevState,
                                        content: prevState.content.filter((ps) => ps.codigo !== codigo)
                                    }))
                                    setObservacaoAluno(null)
                                }
                            }

                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    };

    const handleChangeAlunoTurma = () => {
        setAlunoTurma(null);
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        setIsLoadingSave(true);
        const codObservacaoAluno = observacaoAluno.codigo;
        save(observacaoAluno).then(data => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    toast.success("Salvo com sucesso.", {
                        position: toast.POSITION.BOTTOM_LEFT
                    });
                    if (codObservacaoAluno) {
                        setObservacoesAluno((prevState) => ({
                            ...prevState,
                            content: prevState.content.map((ps) => {
                                if (ps.codigo === codObservacaoAluno) {
                                    return data;
                                } else {
                                    return ps;
                                }
                            })
                        }));
                    } else {
                        setObservacoesAluno((prevState) => ({
                            ...prevState,
                            content: [data, ...prevState.content]
                        }))
                    }
                    setObservacaoAluno(null)
                }
            }
            setIsLoadingSave(false);
        });
    }


    return (
        <div>
            <Container className={classes.paper} component={Paper}>
                <Typography className={classes.title} variant="h6">
                    Observação Aluno
                </Typography>

                {tdp ?
                    <>
                        {alunoTurma ?
                            <>
                                <AlunoTurmaProfileView
                                    alunoTurma={alunoTurma}
                                    handleChangeAlunoTurma={handleChangeAlunoTurma}
                                />
                                {observacaoAluno ?
                                    <ObservacaoAlunoFormV2
                                        observacaoAluno={observacaoAluno}
                                        setObservacaoAluno={setObservacaoAluno}
                                        clickSubmit={clickSubmit}
                                        isLoadingSave={isLoadingSave}
                                    /> :
                                    <ObservacaoAlunoTableV2
                                        tdp={tdp}
                                        alunoTurma={alunoTurma}
                                        isLoading={isLoading}
                                        handleChangePage={handleChangePage}
                                        observacoesAluno={observacoesAluno}
                                        handleDelete={handleDelete}
                                        setObservacaoAluno={setObservacaoAluno}
                                    />
                                }
                            </> :
                            <>
                                <TurmaDisciplinaProfessorProfile
                                    tdp={tdp}
                                    onTdpSelected={setTdp}
                                />
                                <AlunoTurmaTableSelectView
                                    alunosTurma={alunosTurma}
                                    handleSelectAlunoTurma={setAlunoTurma}
                                    isLoading={isLoading}
                                />
                            </>}

                    </> :
                    <TDPTableSelectView
                        tdpsAll={tdpsAll}
                        tdps={tdps}
                        setTdps={setTdps}
                        setTdp={setTdp}
                        isLoading={isLoading}
                        usuariosPerfil={usuariosPerfil}
                        usuarioPerfil={usuarioPerfil}
                        setUsuarioPerfil={setUsuarioPerfil}
                        turmas={turmas}
                        disciplinas={disciplinas}
                        disciplina={disciplina}
                        setDisciplina={setDisciplina}
                        turma={turma}
                        setTurma={setTurma}
                    />
                }
            </Container>

        </div>
    );
}