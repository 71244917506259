import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import React from "react";
import Button from "../UI/Button";
import Loading from "../UI/Loading";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  title: {
    marginBottom: "10px",
  },
});

const TurmaTableSelectView = ({ turmas, onTurmaSelected, isLoading }) => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.title} variant="body1">
        Selecione uma turma
      </Typography>

      {isLoading ? <Loading description="Carregando Turmas..." /> :
        <>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Ano Letivo</TableCell>
                <TableCell>Curso</TableCell>
                <TableCell>Série</TableCell>
                <TableCell>Descrição</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {turmas.map((turma) => (
                <TableRow key={turma.codigo}>
                  <TableCell>{turma.anoLetivo}</TableCell>
                  <TableCell>{turma.curso.descricao}</TableCell>
                  <TableCell>{turma.serie.descricao}</TableCell>
                  <TableCell>{turma.descricao}</TableCell>
                  <TableCell>
                    <Button
                      type="button"
                      variant="contained"
                      color="secondary"
                      onClick={() => onTurmaSelected(turma)}
                      className={classes.submit}
                    >
                      Selecionar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>


          </Table>
        </>}
    </>
  );
};

export default TurmaTableSelectView;
