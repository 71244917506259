import { Container, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { errorMessage } from '../../../util/ConfirmAlertUtil';
import TDPTableSelect from '../../TDP/TDPTableSelect';
import TurmaDisciplinaProfessorProfile from '../../TDP/TurmaDisciplinaProfessorProfile';
import AlunoTurmaTableMultiSelect from '../../AlunoTurma/AlunoTurmaTableMultiSelect';
import { findByTurma } from '../../AlunoTurma/api-aluno-turma';
import ObservacaoAlunoFormV2 from '../v2/ObservacaoAlunoFormV2';
import { saveObservacaoAlunoByAlunoTurma } from '../api-observacao-aluno';
import { toast } from 'react-toastify';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
    },
    title: {
        marginBottom: '20px'
    },
});

const ObservacaoAlunoLoteContainer = () => {
    const classes = useStyles();
    const [tdp, setTdp] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [alunosTurma, setAlunosTurma] = useState([]);
    const [alunosTurmaSelected, setAlunosTurmaSelected] = useState([]);
    const [observacaoAluno, setObservacaoAluno] = useState(null);
    const [isLoadingSave, setIsLoadingSave] = useState(false);


    useEffect(() => {
        if (tdp) {
            setObservacaoAluno((prevState) => ({
                ...prevState,
                tdp: tdp
            }))
            setIsLoading(true);
            findByTurma(tdp.turma.codigo).then((data) => {
                if (data) {
                    if (data.error) {
                        errorMessage(data.message);
                    } else {
                        setAlunosTurma(data);
                    }
                }
                setIsLoading(false);
            })
        }
    }, [tdp]);

    const onTdpSelected = () => {
        setTdp(null);
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        if (alunosTurmaSelected.length === 0) {
            errorMessage("Selecione no mímino 1 aluno.");
            return;
        }
        setIsLoadingSave(true);
        const persist = {
            listAlunoTurma: alunosTurmaSelected,
            observacaoAluno: observacaoAluno
        }
        saveObservacaoAlunoByAlunoTurma(persist).then((data) => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    toast.success("Operação Realizada com sucesso.");
                    setAlunosTurma((prevState) => prevState.map((ps) => ({ ...ps, selected: false })));
                    setObservacaoAluno((prevState) => ({
                        ...prevState,
                        tdp: tdp
                    }));
                }
            }
            setIsLoadingSave(false);
        })
    }

    return (
        <Container className={classes.paper} component={Paper}>
            <Typography className={classes.title} variant="h6">
                Observação Aluno - Lote
            </Typography>
            <Grid container spacing={3}>
                {tdp ?
                    <>
                        <Grid item xs={12}>
                            <TurmaDisciplinaProfessorProfile
                                tdp={tdp}
                                onTdpSelected={onTdpSelected}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <AlunoTurmaTableMultiSelect
                                alunosTurma={alunosTurma}
                                alunosTurmaSelected={alunosTurmaSelected}
                                setAlunosTurmaSelected={setAlunosTurmaSelected}
                                isLoading={isLoading}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ObservacaoAlunoFormV2
                                observacaoAluno={observacaoAluno}
                                setObservacaoAluno={setObservacaoAluno}
                                clickSubmit={clickSubmit}
                                isLoadingSave={isLoadingSave}
                            />
                        </Grid>

                    </> :
                    <>
                        <Grid item xs={12}>
                            <TDPTableSelect
                                setTdp={setTdp}
                            />
                        </Grid>
                    </>
                }

            </Grid>


        </Container>
    )
}

export default ObservacaoAlunoLoteContainer;