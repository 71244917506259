import React, { Component } from 'react';
import PrivateRoute from './auth/PrivateRoute';
import { Route, Switch } from 'react-router-dom';
import Signin from './auth/Signin';
import LivroFormMenu from './shared/components/LateralMenu/Content/LivroFormMenu';
import AlunoTurmaFormMenu from './shared/components/LateralMenu/Content/AlunoTurmaFormMenu';
import NotFoundMenu from './shared/components/LateralMenu/Content/NotFoundMenu';
import LateralMenuContent from './shared/components/LateralMenu/LateralMenuContent';
import PresencaCapituloPlanejamentoTodos from './components/PresencaCapitulo/PresencaCapituloPlanejamentoTodos';
import ObservacaoTurmaContainer from './components/ObservacaoTurma/ObservacaoTurmaContainer';
import ObservacaoAlunoContainer from './components/ObservacaoAluno/ObservacaoAlunoContainer';
import NotaBimestreContainer from './components/NotaBimestre/NotaBimestreContainer';
import RelatorioContainer from './components/Relatorio/RelatorioContainer';
import RelatorioAlunoContainer from './components/Relatorio/Aluno/RelatorioAlunoContainer';
import RelatorioBoletimItinerarioWeb from './components/Relatorio/Web/Boletim/RelatorioBoletimItinerarioWeb';
import RelatorioAtaFinalWeb from './components/Relatorio/Web/RelatorioAtaFinalWeb';
import RelatorioAtaFinalTurmaWeb from './components/Relatorio/Web/AtaFinalTurma/RelatorioAtaFinalTurmaWeb';
import RelatorioRendimentoEscolarWeb from './components/Relatorio/Web/RelatorioRendimentoEscolarWeb';
import AtividadeAlunoContainer from './components/AtividadeAluno/AtividadeAlunoContainer';
import AtividadeContainer from './components/Atividade/AtividadeContainer';
import AusenciaCompensadaContainer from './components/AlunoFrequencia/AusenciaCompensada/AusenciaCompensadaContainer';
import PresencaAlunoContainer from './components/PresencaAluno/PresencaAlunoContainer';
import PresencaContainer from './components/Presenca/PresencaContainer';
import DisciplinaContainer from './components/Disciplina/DisciplinaContainer';
import AlunoTurmaContainer from './components/AlunoTurma/AlunoTurmaContainer';
import PlanejamentoConsultaQuant from './components/Planejamento/PlanejamentoConsultaQuant';
import PlanejamentoContainer from './components/Planejamento/PlanejamentoContainer';
import LivroDisciplinaTurmaForm from './components/TDP/LivroDisciplinaTurmaForm';
import ProfessorDisciplinaTurmaForm from './components/TDP/ProfessorDisciplinaTurmaForm';
import DisciplinaTurmaContainer from './components/TDP/DisciplinaTurmaContainer';
import LivroContainer from './components/Livro/LivroContainer';
import ConfiguracaoContainer from './components/Configuracao/ConfiguracaoContainer';
import BimestreForm from './components/Bimestre/BimestreForm';
import AlunoContainer from './components/Aluno/AlunoContainer';
import Home from './components/Home';
import UsuarioContainer from './components/Usuario/UsuarioContainer';
import PAAlunoContainer from './components/PresencaAluno/Aluno/PAAlunoContainer';
import OAAlunoContainer from './components/ObservacaoAluno/Aluno/OAAlunoContainer';
import AAAlunoContainer from './components/AtividadeAluno/Aluno/AAAlunoContainer';
import NBAlunoContainer from './components/NotaBimestre/Aluno/NBAlunoContainer';
import ComunicadoContainer from "./components/Comunicado/Comunicado/ComunicadoContainer";
import ComunicadoCategoriaContainer from "./components/Comunicado/ComunicadoCategoria/ComunicadoCategoriaContainer";
import ComunicadoUsuarioContainer from "./components/Comunicado/ComunicadoUsuario/ComunicadoUsuarioContainer";
import UsuarioImport from "./components/Usuario/UsuarioImport";
import Curso from "./components/Curso/CursoContainer";
import Serie from "./components/Serie/SerieContainer";
import Turma from "./components/Turma/TurmaContainer";
import AcompanhamentoContainer from "./components/Acompanhamento/AcompanhamentoContainer";

import RespostaContainer from './components/Capitulo/Conteudo/Resposta/RespostaContainer';
import CapituloTable from "./components/Capitulo/CapituloTable";
import CapituloForm from './components/Capitulo/CapituloForm';
import AlunoLivroContainer from "./components/AlunoLivro/AlunoLivroContainer";
import RequestResetPassword from "./auth/RequestResetPassword";
import ResetPassword from "./auth/ResetPassword";
import MensagemTable from "./components/Mensagem/MensagemTable/MensagemTable";
import SalaMensagem from "./components/Mensagem/SalaMensagem/SalaMensagem";
import GroupForm from "./components/Mensagem/Group/GroupForm";
import GroupListParticipants from './components/Mensagem/SalaParticipante/GroupListParticipants';
import EventoContainer from "./components/Evento/Evento/EventoContainer";
import EventoUsuarioContainer from "./components/Evento/EventoUsuario/EventoUsuarioContainer";
import AAAlunoForm from './components/AtividadeAluno/Aluno/AAAlunoForm';
import LateralMenuSkeletonLivro from './shared/components/LateralMenu/LateralMenuSkeletonLivro';
import LateralMenuSkeletonAvaliacaoSecao from './shared/components/LateralMenu/LateralMenuSkeletonAvaliacaoSecao';
import AvaliacaoContainer from './components/Avaliacao/AvaliacaoContainer';
import AvaliacaoConteudoContainer from './components/Avaliacao/Conteudo/AvaliacaoConteudoContainer';
import AvaliacaoResultadoContainer from './components/Avaliacao/Aluno/AvaliacaoResultadoContainer';
import AvaliacaoAlunoContainer from './components/Avaliacao/Aluno/AvaliacaoAlunoContainer';
import LivroInstituicaoContainer from './components/Livro/Instituicao/LivroInstituicaoContainer';
import LivroAutorContainer from './components/Livro/Autor/LivroAutorContainer';
import LivroFormContainer from './components/Livro/LivroFormContainer';
import CronogramaContainer from './components/Cronograma/CronogramaContainer';
import CronogramaAulaContainer from './components/Cronograma/Aula/CronogramaAulaContainer';
import ConteudoTable from './components/Capitulo/Conteudo/ConteudoTable';
import AlunoLivroAddContainer from './components/AlunoLivro/Add/AlunoLivroAddContainer';
import FinanceiroInstituicaoContainer from './components/Financeiro/Instituicao/FinanceiroInstituicaoContainer'
import HistoricoLancamentoContainer from './components/Financeiro/HistoricoLancamento/HistoricoLancamentoContainer';
import AlunoSemResponsavelFinanceiro from './components/Relatorio/Web/PorAnoLetivo/AlunoSemResponsavelFinanceiro';
import ResponsavelFinanceiroSemCelular from './components/Relatorio/Web/PorAnoLetivo/ResponsavelFinanceiroSemCelular';
import FinanceiroResponsavelContainer from './components/Financeiro/Aluno/FinanceiroResponsavelContainer';
import UsuarioResponsavelFinanceiroNuncaAcessou from './components/Relatorio/Web/PorAnoLetivo/UsuarioResponsavelFinanceiroNuncaAcessou';
import SmsReminderContainer from './components/Financeiro/SmsReminder/SmsReminderContainer';
import MensagemFindContainer from './components/Mensagem/MensagemFindContainer';
import ImageUriFullScreen from './components/UI/ImageUriFullScreen';
import PrivatePolicyApp from './shared/components/PrivatePolicy/PrivatePolicyApp';
import IntegralMenu from './components/Integral/IntegralMenu';
import IntegralPresencaContainer from './components/Integral/Presenca/IntegralPresencaContainer';
import IntegralProfessorContainer from './components/Integral/Professor/IntegralProfessorContainer';
import IntegralPresencaAlunoContainer from './components/Integral/PresencaAluno/Aluno/IntegralPresencaAlunoContainer';

import CardapioContainer from './components/Cardapio/CardapioContainer';
import CardapioUsuarioContainer from './components/Cardapio/Usuario/CardapioUsuarioContainer';
import IntegralRotinaContainer from './components/Integral/Rotina/IntegralRotinaContainer';
import IntegralRotinalAlunoContainer from './components/Integral/Rotina/Aluno/IntegralRotinaAlunoContainer';
import SmsPendenciaContainer from './components/Financeiro/SmsPendencia/SmsPendenciaContainer';
import MuralContainer from './components/Mural/MuralContainer';
import MuralFotoUsuarioContainer from './components/Mural/MuralFoto/MuralFotoUsuarioContainer';
import AlunoFotoContainer from './components/AlunoFoto/AlunoFotoContainer';
import AlunoFotoInsert from './components/AlunoFoto/AlunoFotoInsert';
import BimestreContainerMenu from './components/Bimestre/BimestreContainerMenu';
import CronogramaQuantAulaDia from './components/Cronograma/CronogramaQuantAulaDia/CronogramaQuantAulaDiaContainer';
import CronogramaQuantAulaBimestreContainer from './components/Cronograma/CronogramaQuantAulaBimestre/CronogramaQuantAulaBimestreContainer';
import PublicRoute from './auth/PublicRoute';
import UsuarioFormSenha from './components/Usuario/UsuarioFormSenha';
import LivroProfessorContainer from './components/Livro/Professor/LivroProfessorContainer';
import UsuarioDescricaoAutorForm from './components/Usuario/UsuarioDescricaoAutorForm';
import ConferirComPresencaContainer from './components/Cronograma/CronogramaQuantAulaDia/Conferir/ConferirComPresencaContainer';
import CronogramaProfessorContainer from './components/Cronograma/CronogramaProfessor/CronogramaCurrentProfessor/CronogramaCurrentProfessorContainer';
import RelatorioFrequenciaWeb from './components/Relatorio/Web/Frequencia/RelatorioFrequenciaWeb';
import RelatorioConfereRegAulaFaltaWeb from './components/Relatorio/Web/RelatorioConfereRegAulaFaltaWeb';
import ComunicadoBannerContainer from './components/Comunicado/ComunicadoBanner/ComunicadoBannerContainer';
import LocalContainer from './components/Local/LocalContainer';
import MensagemFindAllContainer from './components/Mensagem/MensagemFindAllContainer';
import RltFrequenciaPorAlunoMesAnoLetivo from './components/Relatorio/Web/FrequenciaPorAlunoMesAnoLetivo/RltFrequenciaPorAlunoMesAnoLetivo';
import CatracaRegistroUsuarioContainer from './components/CatracaRegistro/CatracaRegistroUsuario/CatracaRegistroUsuarioContainer';
import PresencaLoteContainer from './components/Presenca/PresencaLote/PresencaLoteContainer';
import PresencaMenu from './components/Presenca/PresencaMenu';
import PresencaTurmaContainer from './components/Presenca/PresencaTurma/PresencaTurmaContainer';
import PresencaDisciplinaContainer from './components/Presenca/PresencaDisciplina/PresencaDisciplinaContainer';
import CatracaRegistroContainer from './components/CatracaRegistro/CatracaRegistroContainer';
import PresencaUsuarioContainer from './components/Presenca/PresencaUsuario/PresencaUsuarioContainer';
import CatracaRegistroMenu from './components/CatracaRegistro/CatracaRegistroMenu';
import CatracaRegistroCheckContainer from './components/CatracaRegistro/CatracaRegistroCheck/CatracaRegistroCheckContainer';
import AlunoFrequenciaLoteContainer from './components/AlunoFrequencia/AlunoFrequenciaLote/AlunoFrequenciaLoteContainer';
import ObservacaoAlunoContainerV2 from './components/ObservacaoAluno/v2/ObservacaoAlunoContainerV2';
import ObservacaoAlunoMenu from './components/ObservacaoAluno/ObservacaoAlunoMenu';
import ObservacaoAlunoLoteContainer from './components/ObservacaoAluno/Lote/ObservacaoAlunoLoteContainer';
import PresencaPorDataContainer from './components/Presenca/PresencaPorData/PresencaPorDataContainer';

class MenuRouter extends Component {
  render() {
    return (<div>
      <Switch>
        <Route exact path="/" component={Signin} />
        <Route exact path="/private-policy-app" component={PrivatePolicyApp} />
        <Route exact path="/signin" component={Signin} />
        <Route exact path="/request-reset-password" component={RequestResetPassword} />
        <Route path="/reset-password/:codResetPassword" component={ResetPassword} />
        <PublicRoute path="/conteudos/:codLivro" component={LateralMenuSkeletonLivro} child={ConteudoTable} />
        <PublicRoute path="/conteudos/:codLivro/:codCapitulo" component={LateralMenuSkeletonLivro} child={ConteudoTable} />
        <PrivateRoute exact path="/home" component={LateralMenuContent} child={Home} />
        <PrivateRoute exact path="/alunos" component={LateralMenuContent} child={AlunoContainer} />
        <PrivateRoute exact path="/bimestre" component={LateralMenuContent} child={BimestreContainerMenu} />
        <PrivateRoute exact path="/bimestre-form" component={LateralMenuContent} child={BimestreForm} />
        <PrivateRoute exact path="/turma" component={LateralMenuContent} child={Turma} />
        <PrivateRoute exact path="/usuarios" component={LateralMenuContent} child={UsuarioContainer} />
        <PrivateRoute exact path="/usuario-senha" component={LateralMenuContent} child={UsuarioFormSenha} />
        <PrivateRoute exact path="/configuracao" component={LateralMenuContent} child={ConfiguracaoContainer} />
        <PrivateRoute exact path="/livros" component={LateralMenuContent} child={LivroContainer} />
        <PrivateRoute exact path="/livro-instituicao" component={LateralMenuContent} child={LivroInstituicaoContainer} />
        <PrivateRoute exact path="/livro-professor" component={LateralMenuContent} child={LivroProfessorContainer} />
        <PrivateRoute exact path="/livro-form" component={LateralMenuContent} child={LivroFormContainer} />
        <PrivateRoute exact path="/livro" component={LivroFormMenu} />
        <PrivateRoute exact path="/livro-capitulos" component={LateralMenuContent} child={CapituloTable} />
        <PrivateRoute exact path="/livro-capitulo" component={LateralMenuContent} child={CapituloForm} />
        <PrivateRoute exact path="/conteudos" component={LateralMenuSkeletonLivro} child={ConteudoTable} />
        <PrivateRoute exact path="/disciplina-turma" component={LateralMenuContent} child={DisciplinaTurmaContainer} />
        <PrivateRoute exact path="/professor-disciplina-turma" component={LateralMenuContent} child={ProfessorDisciplinaTurmaForm} />
        <PrivateRoute exact path="/livro-disciplina-turma" component={LateralMenuContent} child={LivroDisciplinaTurmaForm} />
        <PrivateRoute exact path="/planejamento" component={LateralMenuContent} child={PlanejamentoContainer} />
        <PrivateRoute exact path="/planejamento-consulta-quant" component={LateralMenuContent} child={PlanejamentoConsultaQuant} />
        <PrivateRoute exact path="/aluno-turma" component={LateralMenuContent} child={AlunoTurmaContainer} />
        <PrivateRoute exact path="/aluno-turma-form" component={AlunoTurmaFormMenu} />
        <PrivateRoute exact path="/disciplina" component={LateralMenuContent} child={DisciplinaContainer} />
        <PrivateRoute exact path="/presenca" component={LateralMenuContent} child={PresencaContainer} />
        <PrivateRoute exact path="/presenca-menu" component={LateralMenuContent} child={PresencaMenu} />
        <PrivateRoute exact path="/presenca-por-disciplina" component={LateralMenuContent} child={PresencaDisciplinaContainer} />
        <PrivateRoute exact path="/presenca-por-turma" component={LateralMenuContent} child={PresencaTurmaContainer} />
        <PrivateRoute exact path="/presenca-por-usuario" component={LateralMenuContent} child={PresencaUsuarioContainer} />
        <PrivateRoute exact path="/presenca-por-data" component={LateralMenuContent} child={PresencaPorDataContainer} />
        <PrivateRoute exact path="/presenca-aluno" component={LateralMenuContent} child={PresencaAlunoContainer} />
        <PrivateRoute exact path="/atividade" component={LateralMenuContent} child={AtividadeContainer} />
        <PrivateRoute exact path="/ausencia-compensada" component={LateralMenuContent} child={AusenciaCompensadaContainer} />
        <PrivateRoute exact path="/aluno-frequencia-lote" component={LateralMenuContent} child={AlunoFrequenciaLoteContainer} />
        <PrivateRoute exact path="/atividade-aluno" component={LateralMenuContent} child={AtividadeAlunoContainer} />
        <PrivateRoute exact path="/relatorio" component={LateralMenuContent} child={RelatorioContainer} />
        <PrivateRoute exact path="/relatorio-aluno" component={LateralMenuContent} child={RelatorioAlunoContainer} />
        <PrivateRoute exact path="/relatorio-boletim-web" component={LateralMenuContent} child={RelatorioBoletimItinerarioWeb} />
        <PrivateRoute exact path="/relatorio-ata-final-web" component={LateralMenuContent} child={RelatorioAtaFinalWeb} />
        <PrivateRoute exact path="/relatorio-ata-final-turma-web" component={LateralMenuContent} child={RelatorioAtaFinalTurmaWeb} />
        <PrivateRoute exact path="/relatorio-rendimento-escolar-web" component={LateralMenuContent} child={RelatorioRendimentoEscolarWeb} />
        <PrivateRoute exact path="/nota-bimestre" component={LateralMenuContent} child={NotaBimestreContainer} />
        <PrivateRoute exact path="/observacao-aluno" component={LateralMenuContent} child={ObservacaoAlunoContainer} />
        <PrivateRoute exact path="/observacao-aluno-v2" component={LateralMenuContent} child={ObservacaoAlunoContainerV2} />
        <PrivateRoute exact path="/observacao-aluno-menu" component={LateralMenuContent} child={ObservacaoAlunoMenu} />
        <PrivateRoute exact path="/observacao-aluno-lote" component={LateralMenuContent} child={ObservacaoAlunoLoteContainer} />
        <PrivateRoute exact path="/observacao-turma" component={LateralMenuContent} child={ObservacaoTurmaContainer} />
        <PrivateRoute exact path="/presenca-capitulo-planejamento" component={LateralMenuContent} child={PresencaCapituloPlanejamentoTodos} />


        <PrivateRoute exact path="/aluno/presenca-aluno" component={LateralMenuContent} child={PAAlunoContainer} />
        <PrivateRoute exact path="/observacao-user" component={LateralMenuContent} child={OAAlunoContainer} />
        <PrivateRoute exact path="/aluno/atividade-aluno" component={LateralMenuContent} child={AAAlunoContainer} />
        <PrivateRoute exact path="/aluno/nota-bimestre-aluno" component={LateralMenuContent} child={NBAlunoContainer} />
        <PrivateRoute exact path="/comunicado" component={LateralMenuContent} child={ComunicadoContainer} />
        <PrivateRoute exact path="/comunicado-categoria" component={LateralMenuContent} child={ComunicadoCategoriaContainer} />
        <PrivateRoute exact path="/comunicado-aluno" component={LateralMenuContent} child={ComunicadoUsuarioContainer} />
        <PrivateRoute exact path="/usuario-import" component={LateralMenuContent} child={UsuarioImport} />
        <PrivateRoute exact path="/curso" component={LateralMenuContent} child={Curso} />
        <PrivateRoute exact path="/serie" component={LateralMenuContent} child={Serie} />
        <PrivateRoute exact path="/acompanhamento" component={LateralMenuContent} child={AcompanhamentoContainer} />
        <PrivateRoute exact path="/correcao" component={LateralMenuContent} child={RespostaContainer} />
        <PrivateRoute exact path="/aluno-livro" component={LateralMenuContent} child={AlunoLivroContainer} />

        <PrivateRoute exact path="/mensagem" component={LateralMenuContent} child={MensagemTable} />
        <PrivateRoute exact path="/sala-mensagem" component={LateralMenuContent} child={SalaMensagem} />
        <PrivateRoute exact path="/group-form" component={LateralMenuContent} child={GroupForm} />
        <PrivateRoute exact path="/mensagem-find" component={LateralMenuContent} child={MensagemFindContainer} />
        <PrivateRoute exact path="/mensagem-find-all" component={LateralMenuContent} child={MensagemFindAllContainer} />
        <PrivateRoute exact path="/group-list-participants" component={LateralMenuContent} child={GroupListParticipants} />

        <PrivateRoute exact path="/evento" component={LateralMenuContent} child={EventoContainer} />
        <PrivateRoute exact path="/evento-aluno" component={LateralMenuContent} child={EventoUsuarioContainer} />

        <PrivateRoute exact path="/atividade-aluno-anexo" component={LateralMenuContent} child={AAAlunoForm} />

        <PrivateRoute exact path="/avaliacao" component={LateralMenuContent} child={AvaliacaoContainer} />
        <PrivateRoute exact path="/avaliacao-secao" component={LateralMenuSkeletonAvaliacaoSecao} child={AvaliacaoConteudoContainer} />
        <PrivateRoute exact path="/avaliacao-resultado" component={LateralMenuContent} child={AvaliacaoResultadoContainer} />
        <PrivateRoute exact path="/avaliacao-aluno" component={LateralMenuContent} child={AvaliacaoAlunoContainer} />

        <PrivateRoute exact path="/livro-autor" component={LateralMenuContent} child={LivroAutorContainer} />
        <PrivateRoute exact path="/cronograma" component={LateralMenuContent} child={CronogramaContainer} />
        <PrivateRoute exact path="/cronograma-professor" component={LateralMenuContent} child={CronogramaProfessorContainer} />
        <PrivateRoute exact path="/cronograma-aula" component={LateralMenuContent} child={CronogramaAulaContainer} />
        <PrivateRoute exact path="/livro-aluno" component={LateralMenuContent} child={AlunoLivroAddContainer} />

        <PrivateRoute exact path="/financeiro-instituicao" component={LateralMenuContent} child={FinanceiroInstituicaoContainer} />
        <PrivateRoute exact path="/financeiro-responsavel" component={LateralMenuContent} child={FinanceiroResponsavelContainer} />
        <PrivateRoute exact path="/historico-lancamento" component={LateralMenuContent} child={HistoricoLancamentoContainer} />
        <PrivateRoute exact path="/sms-reminder-lancamento" component={LateralMenuContent} child={SmsReminderContainer} />
        <PrivateRoute exact path="/sms-pendencia" component={LateralMenuContent} child={SmsPendenciaContainer} />
        <PrivateRoute exact path="/aluno-sem-responsavel-financeiro" component={LateralMenuContent} child={AlunoSemResponsavelFinanceiro} />
        <PrivateRoute exact path="/responsavel-financeiro-sem-celular" component={LateralMenuContent} child={ResponsavelFinanceiroSemCelular} />
        <PrivateRoute exact path="/responsavel-financeiro-nunca-acessou-sistema" component={LateralMenuContent} child={UsuarioResponsavelFinanceiroNuncaAcessou} />
        <PrivateRoute exact path="/image-full-screen" component={LateralMenuContent} child={ImageUriFullScreen} />
        <PrivateRoute exact path="/integral" component={LateralMenuContent} child={IntegralMenu} />
        <PrivateRoute exact path="/integral-presenca" component={LateralMenuContent} child={IntegralPresencaContainer} />
        <PrivateRoute exact path="/integral-professor" component={LateralMenuContent} child={IntegralProfessorContainer} />
        <PrivateRoute exact path="/integral-presenca-aluno" component={LateralMenuContent} child={IntegralPresencaAlunoContainer} />

        <PrivateRoute exact path="/cardapio" component={LateralMenuContent} child={CardapioContainer} />
        <PrivateRoute exact path="/cardapio-usuario" component={LateralMenuContent} child={CardapioUsuarioContainer} />

        <PrivateRoute exact path="/rotina" component={LateralMenuContent} child={IntegralRotinaContainer} />
        <PrivateRoute exact path="/rotina-usuario" component={LateralMenuContent} child={IntegralRotinalAlunoContainer} />

        <PrivateRoute exact path="/mural" component={LateralMenuContent} child={MuralContainer} />
        <PrivateRoute exact path="/mural-foto-usuario" component={LateralMenuContent} child={MuralFotoUsuarioContainer} />

        <PrivateRoute exact path="/aluno-foto" component={LateralMenuContent} child={AlunoFotoContainer} />
        <PrivateRoute exact path="/aluno-foto-insert" component={LateralMenuContent} child={AlunoFotoInsert} />

        <PrivateRoute exact path="/cronograma-quant-aula-bimestre" component={LateralMenuContent} child={CronogramaQuantAulaBimestreContainer} />
        <PrivateRoute exact path="/cronograma-quant-aula-dia" component={LateralMenuContent} child={CronogramaQuantAulaDia} />
        <PrivateRoute exact path="/descricao-autor" component={LateralMenuContent} child={UsuarioDescricaoAutorForm} />
        <PrivateRoute exact path="/conferir-cronograma-presenca" component={LateralMenuContent} child={ConferirComPresencaContainer} />
        <PrivateRoute exact path="/relatorio-frequencia-web" component={LateralMenuContent} child={RelatorioFrequenciaWeb} />
        <PrivateRoute exact path="/confere-registro-aula-falta" component={LateralMenuContent} child={RelatorioConfereRegAulaFaltaWeb} />
        <PrivateRoute exact path="/comunicado-banner" component={LateralMenuContent} child={ComunicadoBannerContainer} />
        <PrivateRoute exact path="/local" component={LateralMenuContent} child={LocalContainer} />
        <PrivateRoute exact path="/relatorio-frequencia-por-aluno-mes-ano-letivo" component={LateralMenuContent} child={RltFrequenciaPorAlunoMesAnoLetivo} />
        <PrivateRoute exact path="/monitor-acesso-menu" component={LateralMenuContent} child={CatracaRegistroMenu} />
        <PrivateRoute exact path="/monitor-acesso-check" component={LateralMenuContent} child={CatracaRegistroCheckContainer} />
        <PrivateRoute exact path="/monitor-acesso" component={LateralMenuContent} child={CatracaRegistroContainer} />
        <PrivateRoute exact path="/monitor-acesso-usuario" component={LateralMenuContent} child={CatracaRegistroUsuarioContainer} />
        <PrivateRoute exact path="/presenca-lote" component={LateralMenuContent} child={PresencaLoteContainer} />
        <Route component={NotFoundMenu} />
      </Switch>
    </div>)
  }
}

export default MenuRouter
