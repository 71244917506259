import { Checkbox, Grid, makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { dateFormat } from '../../util/DateUtil';
import Loading from '../UI/Loading';
import Button from '../UI/Button';

const useStyles = makeStyles({
    container: {
        marginTop: '20px'
    },
    table: {
        minWidth: 650,
    },
    novoRegistro: {
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});

const AlunoTurmaTableMultiSelect = ({ alunosTurma, alunosTurmaSelected, setAlunosTurmaSelected, isLoading }) => {
    const classes = useStyles();


    const handleOnATSelected = (event, at) => {


        if (event.target.checked) {
            setAlunosTurmaSelected((prevState) => ([
                ...prevState,
                at
            ]));
        } else {
            setAlunosTurmaSelected((prevState) => prevState.filter((ps) => ps.codigo !== at.codigo));
        }

    }

    const handleDesmarcarTodos = () => {
        setAlunosTurmaSelected([]);
    }

    const handleMarcarTodos = () => {
        setAlunosTurmaSelected(alunosTurma);
    }

    return (
        <>
            {isLoading ?
                <Loading description="Carregando Alunos Turma" /> :
                <>
                    <Grid container style={{ marginTop: 2, marginBottom: 4 }} direction='row' spacing={3}>
                        <Grid item>
                            <Button onClick={handleDesmarcarTodos}>Desmarcar Todos</Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={handleMarcarTodos}>Marcar Todos</Button>
                        </Grid>
                    </Grid>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Nome</TableCell>
                                <TableCell>Número Chamada</TableCell>
                                <TableCell>Data Entrada</TableCell>
                                <TableCell>Data Saída</TableCell>
                                <TableCell>Situação</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {alunosTurma.map((at) => (
                                <TableRow key={at.codigo}>
                                    <TableCell>
                                        <Checkbox
                                            checked={alunosTurmaSelected.some((alunoTurma) => alunoTurma.codigo === at.codigo)}
                                            onChange={(event) => handleOnATSelected(event, at)}
                                        />
                                    </TableCell>
                                    <TableCell>{at.aluno.nome}</TableCell>
                                    <TableCell>{at.numeroChamada}</TableCell>
                                    <TableCell>{dateFormat(at.dataEntrada)}</TableCell>
                                    <TableCell>{at.dataSaida ? dateFormat(at.dataSaida) : ''}</TableCell>
                                    <TableCell>{at.ativo ? "Ativo" : 'Desativado'}</TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </>

            }
        </>
    );
}

export default AlunoTurmaTableMultiSelect;