import { SERVER_URL } from '../../constants';
import {
  fetchGetAuth} from '../../util/FetchUtil';

const controller = 'legenda-diario/';

export const findLegendaDiarioAll = () => {
  const url = SERVER_URL + controller + 'query';
  return fetchGetAuth(url);
}
