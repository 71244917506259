import { Container, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import Button from '../UI/Button';
import { Link } from 'react-router-dom';


const useStyles = makeStyles({
    paper: {
        padding: '20px',
    },
    title: {
        marginBottom: '20px'
    },
});

const ObservacaoAlunoMenu = () => {
    const classes = useStyles();

    return (
        <Container className={classes.paper} component={Paper}>
            <Typography className={classes.title} variant="h6">
                Observação Aluno
            </Typography>
            <Grid container direction='row' spacing={3}>
                <Grid item>
                    <Button
                        component={Link}
                        to="/observacao-aluno-v2"
                    >Por Aluno</Button>
                </Grid>
                <Grid item>
                    <Button
                        component={Link}
                        to="/observacao-aluno-lote"
                    >Por Lote</Button>
                </Grid>
            </Grid>


        </Container>
    )
}

export default ObservacaoAlunoMenu;