import { SERVER_URL } from '../../constants';
import { fetchGetAuth, fetchRemoveAuth, fetchPostAuth } from '../../util/FetchUtil';

const controller = 'presenca/';

const page = (codTdp, pageNumber) => {
  const url = SERVER_URL + controller + 'page/tdp/' + codTdp + '?page=' + pageNumber;
  return fetchGetAuth(url);
}

export const findByTdpTurmaCodigo = (codTurma, page) => {
  const url = SERVER_URL + controller + 'page-query?codTurma=' + codTurma + '&page=' + page;
  return fetchGetAuth(url);
}
export const findPresencaByData = (dataCalendar, page) => {
  const url = SERVER_URL + controller + 'page-query?data-long=' + dataCalendar.getTime() + '&page=' + page;
  return fetchGetAuth(url);
}

export const findByTdpDisciplinaCodDisciplina = (codDisciplina, page) => {
  const url = SERVER_URL + controller + 'page-query?codDisciplina=' + codDisciplina + '&page=' + page;
  return fetchGetAuth(url);
}

export const findByTdpUsuarioId = (codUsuario, page) => {
  const url = SERVER_URL + controller + 'page-query?codUsuario=' + codUsuario + '&page=' + page;
  return fetchGetAuth(url);
}

export const findByTdpCodigoAndDataMonth = (codTdp, pageNumber, month) => {
  const url = SERVER_URL + controller + 'page-query?codTdp=' + codTdp + '&page=' + pageNumber + '&month=' + month;
  return fetchGetAuth(url);
}

const findByCodigo = (codAluno) => {
  const url = SERVER_URL + controller + 'codigo/' + codAluno;
  return fetchGetAuth(url);
}

const remove = (codPresenca) => {
  const url = SERVER_URL + controller + 'delete/' + codPresenca;
  return fetchRemoveAuth(url);
}

const save = (presenca) => {
  const url = SERVER_URL + controller + 'save';
  return fetchPostAuth(url, presenca);
}

const duplicarPresenca = (codPresenca) => {
  const url = SERVER_URL + controller + 'duplicar/' + codPresenca;
  return fetchPostAuth(url);
}

export const serviceIncludeByDate = (dataPresenca) => {
  const url = SERVER_URL + controller + "serviceIncludeByDate/" + dataPresenca.getTime();
  return fetchGetAuth(url);
}

export const serviceExcludeByDate = (dataPresenca) => {
  const url = SERVER_URL + controller + "serviceExcludeByDate/" + dataPresenca.getTime();
  return fetchGetAuth(url);
}



export {
  page,
  save,
  remove,
  findByCodigo,
  duplicarPresenca
}