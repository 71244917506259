import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { Divider, Grid } from "@material-ui/core";
import { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import {
  createUpdate,
  createUpdatePorBimestre,
  createUpdatePorBimestreTurma,
} from "../api-aluno-frequencia";
import { errorMessage } from "../../../util/ConfirmAlertUtil";
import { toast } from "react-toastify";
import LoadingDialog from "../../UI/LoadingDialog";
import Button from "../../UI/Button";
import Select from "../../UI/Select";
import { findByAnoLetivo } from "../../Turma/api-turma";

const useStyles = makeStyles({
  paper: {
    padding: "20px",
  },
  title: {
    marginBottom: "20px",
  },
  cursorLink: {
    cursor: "pointer",
  },
});

export default function AlunoFrequenciaLoteContainer() {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const listBimestres = [
    { value: 1, description: "1º Bimestre" },
    { value: 2, description: "2º Bimestre" },
    { value: 3, description: "3º Bimestre" },
    { value: 4, description: "4º Bimestre" },
  ];
  const [bimestre, setBimestre] = useState(null);
  const [listTurmas, setListTurmas] = useState([]);
  const [turma, setTurma] = useState(null);

  useEffect(() => {
    findByAnoLetivo().then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListTurmas(data);
        }
      }
    });
  }, []);
  const handleCreateUpdateAllInstitution = () => {
    confirmAlert({
      title: "Alerta",
      message:
        "Deseja criar/atualizar todos registros de aluno freq. da instituição?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            setOpenDialog(true);
            createUpdate().then((data) => {
              if (data) {
                if (data.error) {
                  errorMessage(data.message);
                } else {
                  toast.success("Operação realizada com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT,
                  });
                }
                setOpenDialog(false);
              }
            });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };
  const handleCreateUpdatePorBimestre = () => {
    if (bimestre === null) {
      errorMessage("Selecione um Bimestre");
      return;
    }
    confirmAlert({
      title: "Alerta",
      message:
        "Deseja criar/atualizar todos registros de aluno freq. por bimestre?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            setOpenDialog(true);
            createUpdatePorBimestre(bimestre.value).then((data) => {
              if (data) {
                if (data.error) {
                  errorMessage(data.message);
                } else {
                  toast.success("Operação realizada com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT,
                  });
                }
                setOpenDialog(false);
              }
            });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  const handleCreateUpdatePorBimestreTurma = () => {
    if (bimestre === null) {
      errorMessage("Selecione um Bimestre");
      return;
    }
    if (turma === null) {
      errorMessage("Selecione uma Turma");
      return;
    }
    confirmAlert({
      title: "Alerta",
      message:
        "Deseja criar/atualizar todos registros de aluno freq. por bimestre e turma?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            setOpenDialog(true);
            createUpdatePorBimestreTurma(
              bimestre.numeroBimestre,
              turma.codigo
            ).then((data) => {
              if (data) {
                if (data.error) {
                  errorMessage(data.message);
                } else {
                  toast.success("Operação realizada com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT,
                  });
                }
                setOpenDialog(false);
              }
            });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  const onChange = (event) => {
    setBimestre(
      listBimestres.find((item) => item.value === event.target.value)
    );
  };

  const onChangeTurma = (event) => {
    setTurma(listTurmas.find((turma) => turma.codigo === event.target.value));
  };

  return (
    <Container className={classes.paper} component={Paper}>
      <LoadingDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        title="Aluno Frequência"
        description="Criando/Atualizando"
      />
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <Typography className={classes.title} variant="h6">
            Aluno Frequência Lote
          </Typography>
        </Grid>
      </Grid>

      <Grid container direction="column" spacing={5}>
        <Grid item xs={12}>
          <Button onClick={handleCreateUpdateAllInstitution}>
            Criar/Atualizar Aluno Freq. Toda Instituição
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={3}>
              <Select
                label="Bimestre"
                value={bimestre && bimestre.value ? bimestre.value : null}
                list={listBimestres}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={5}>
              <Button onClick={handleCreateUpdatePorBimestre}>
                Criar/Atualizar Aluno Freq. Por Bimestre
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={3}>
              <Select
                label="Bimestre"
                value={bimestre && bimestre.value ? bimestre.value : null}
                list={listBimestres}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={3}>
              <Select
                label="Turma"
                value={turma && turma.codigo ? turma.codigo : null}
                list={listTurmas}
                onChange={onChangeTurma}
              />
            </Grid>
            <Grid item xs={5}>
              <Button onClick={handleCreateUpdatePorBimestreTurma}>
                Criar/Atualizar Aluno Freq. Por Bimestre e Turma
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
