import { SERVER_URL } from '../../constants';
import {
  fetchGetAuth,
  fetchPostAuth
} from '../../util/FetchUtil';

const controller = 'presenca-aluno/';

const findByPresenca = (codigo) => {
  const url = SERVER_URL + controller + 'presenca/' + codigo;
  return fetchGetAuth(url);
}

const save = (presencaAluno) => {
  const url = SERVER_URL + controller + 'save';
  return fetchPostAuth(url, presencaAluno);
}

const presente = (codPA) => {
  const url = SERVER_URL + controller + 'presente/' + codPA;
  return fetchPostAuth(url);
}

const faltou = (codPA) => {
  const url = SERVER_URL + controller + 'faltou/' + codPA;
  return fetchPostAuth(url);
}

const findByTdpCodigoAndCurrentUser = (codTDP, pageNumber) => {
  const url = SERVER_URL + controller + "aluno/tdp/" + codTDP + '?page=' + pageNumber;
  return fetchGetAuth(url);
}

const getPresencaAlunoResumo = (codTDP) => {
  const url = SERVER_URL + controller + "aluno/resumo/tdp/" + codTDP;
  return fetchGetAuth(url);
}

const insertByStudent = (codAlunoTurma) => {
  const url = SERVER_URL + controller + "insert-student/" + codAlunoTurma;
  return fetchGetAuth(url);
}

export const findFrequenciaAlunoMesAnoLetivo = (codAlunoEscola, mes, anoLetivo) => {
  const url = SERVER_URL + controller + "frequencia-aluno-mes-ano-letivo/" + codAlunoEscola + "/" + mes + "/" + anoLetivo;
  return fetchGetAuth(url);
}

export const serviceUpdateByDate = (dataPresenca) => {
  const url = SERVER_URL + controller + "serviceUpdateByDate/" + dataPresenca.getTime();
  return fetchGetAuth(url);
}



export const serviceUpdateByDateAndTurma = (dataPresenca, codTurma) => {
  const url = SERVER_URL + controller + "serviceUpdateByDateAndTurma/" + dataPresenca.getTime() + "/" + codTurma;
  return fetchGetAuth(url);
}

export const serviceUpdateByDateAndLegendaDiario = (dataPresenca, codLegendaDiario) => {
  const url = SERVER_URL + controller + "serviceUpdateByDateAndLegendaDiario/" + dataPresenca.getTime() + "/" + codLegendaDiario;
  return fetchGetAuth(url);
}

export {
  findByPresenca,
  save,
  presente,
  faltou,
  findByTdpCodigoAndCurrentUser,
  getPresencaAlunoResumo,
  insertByStudent
}